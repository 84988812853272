import {Component, OnInit} from '@angular/core';
import {CommonService} from '../services/common/common.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.css']
})
export class ForgetpasswordComponent implements OnInit {

  userData: any = {};
  resetForm: FormGroup;


    constructor(public userService: CommonService,
                private _formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private toastr: ToastrService) {

    }

  ngOnInit() {
      window.scrollTo(0, 0);

      // Validation for login form
      this.resetForm = this._formBuilder.group({
          email: ['', [Validators.required, Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')]],
      });

  }


  doResetEmail(value) {

      this.userService.passwordResetEmail(value).subscribe((response) => {

          if (response.status) {
              // console.log(response.message);
              this.toastr.success(response.message, 'Success');
              this.router.navigate(['/']);

          } else {
              // console.log(response);
              this.toastr.error(response.message, 'Error');
          }

      });


  }

    back(){

        this.router.navigate(['']);
    }

}
