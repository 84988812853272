import { Component, OnInit,ChangeDetectionStrategy,ChangeDetectorRef } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import { SafeResourceUrl,DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-preview',
  templateUrl: 'preview.component.html',
  styleUrls: ['preview.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewComponent implements OnInit {
  userToken:any;
    imageToShow: any;
  fileDetail:any;
  current:any=false;
  showImage:boolean=false;
  myTemplate: any = "";
  filePath:any;


  constructor(private route: ActivatedRoute, public http:HttpClient ,private sanitizer: DomSanitizer,private cd: ChangeDetectorRef) {

    this.userToken = localStorage.getItem('token')
    this.fileDetail = localStorage.getItem('path');

   this.route.queryParams.subscribe(params => {

      //this.filePath= params['filemanager'];

      if(params['filemanager'] == 'true'){

        this.filePath= 1;

      }
      else{

        this.filePath= 0;
      }

    });
  }

  ngOnInit() {
    this.cd.detach();
    this.getFileDetail();
    $('#myFrame').ready(function()  {
      alert("Loaded");
    });

  }

  getFileDetail() {
      var encodeURL:any =   encodeURI(environment.BASE_URL+'file/response?path='+this.fileDetail+'&token='+this.userToken+'&filemanager='+this.filePath);
    this.http.get(encodeURL,{responseType:'blob'} ).toPromise().then((response:any) => {

        let data = new Blob([response],{ type: response.type });

      this.createImageFromBlob(data);

    });


  }

  createImageFromBlob(image) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      var data:any = reader.result;
      // var data:string = reader.result;

      this.imageToShow= this.sanitizer.bypassSecurityTrustResourceUrl(data);

      console.log(this.imageToShow);
      this.showImage= true;
      //this.cd.detectChanges();
      this.cd.detectChanges();


    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }





}
