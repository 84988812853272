import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment as env} from '../../../environments/environment';
import { Compiler } from '@angular/core';
import {CommonService} from "../../services/common/common.service";
import {ToastrService} from "ngx-toastr";




@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    styleUrls: ['header.component.css']
})
export class HeaderComponent implements OnInit {

    loggedUserName: any = '';
    role:any;
    multiRoles:any=[];

    constructor(private router: Router,private _compiler: Compiler,public  commonService:CommonService,public toastr:ToastrService) {

    }


    ngOnInit() {    

        this.loggedUserName = localStorage.getItem('user_name');
        this.role =localStorage.getItem('role');

        let roles:any=[]; 
          roles= JSON.parse(localStorage.getItem('multi_role'));    
      for(let role of roles){

        if(role == env.HR_ROLE){
             this.multiRoles.push({role_id:role,name:"HR"});
        }
        if(role == env.EMPLOYEE_ROLE){

            this.multiRoles.push({role_id:role,name:"Employee"});
        }
        if(role == env.RECRUITMENT_ROLE){

            this.multiRoles.push({role_id:role,name:"Recruiter"});
        }
        if(role == env.MANAGER_ROLE){

            this.multiRoles.push({role_id:role,name:"Manager"});
        }
        if(role == env.FINANCE){

            this.multiRoles.push({role_id:role,name:"Finance"});
        }

        if(role == env.GHR){

            this.multiRoles.push({role_id:role,name:"GHR"});
        }
        if(role == env.ADMIN_ROLE){

            this.multiRoles.push({role_id:role,name:"Admin"});
        }

        }
    }

    logout() {


        this.commonService.callLogoutApi().subscribe((response: any)=>{

            if(response.status){
                this.toastr.success(response.message,"Success!!!");
                localStorage.clear();
                localStorage.removeItem('token');
                this.router.navigate(['']);

            }

        })

    }

    ngOnDestroy(){

        localStorage.removeItem('token');

    }

    changeRole(event){

        this.router.navigate(['']);

        localStorage.setItem("role",event);

    }

    sidemenu() {
        const x = document.getElementById('container');

        if (x.className === 'fixed-header') {
            x.classList.add('sidebar-closed');
        }
        else if (x.className === 'fixed-header sidebar-closed') {
            x.classList.remove('sidebar-closed');
        }
    }

    mobilesideMenu(){

        const x = document.getElementById('mobileheader');

        if (x.className === 'Site-content') {

            x.classList.add('nav-open');
        }
        else if (x.className === 'Site-content nav-open') {
            x.classList.remove('nav-open');
        }
    }

    openGuide() {

        window.open(env.SITE_URL + 'guide');
    }

    clearCache(){

        localStorage.clear();
        localStorage.removeItem('token');
        this.router.navigate(['']);
        window.location.reload();
    }


}
