import {Component, OnInit} from '@angular/core';
import {CommonService} from '../services/common/common.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {environment as env} from '../../environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    userData: any = {};
    loginForm: FormGroup;
    returnUrl: string;

    constructor(public userService: CommonService,
                private _formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private toastr: ToastrService) {

        //  this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';

    }
    
    public today() {
        return new Date().toISOString().substring(0, 10);
    }
    ngOnInit() {

        window.scrollTo(0, 0);
        // Validation for login form
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')]],
            password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]]
        });
        this.loadBasedOnRole();

    }

    doLogin(value) {
        this.userService.loginSubmit(value).subscribe((response) => {
            if (response.status) {
                this.toastr.success('Your Login Is Successful..', 'Success!!!');
                let roles = response.data.role;
                localStorage.setItem('multi_role',JSON.stringify(roles));
                if (roles.indexOf(parseInt(env.HR_ROLE)) != -1) {
                    localStorage.setItem('role', env.HR_ROLE);
                }
                else if (roles.indexOf(parseInt(env.MANAGER_ROLE)) != -1) {
                    localStorage.setItem('role', env.MANAGER_ROLE);
                } else if (roles.indexOf(parseInt(env.FINANCE)) != -1) {
                    localStorage.setItem('role', env.FINANCE);
                } else if (roles.indexOf(parseInt(env.RECRUITMENT_ROLE)) != -1) {
                    localStorage.setItem('role', env.RECRUITMENT_ROLE);
                }else if (roles.indexOf(parseInt(env.EMPLOYEE_ROLE)) != -1) {
                    localStorage.setItem('role', env.EMPLOYEE_ROLE);
                } else if (roles.indexOf(parseInt(env.CANDIDATE_ROLE)) != -1) {
                    localStorage.setItem('role', env.CANDIDATE_ROLE);
                }else if (roles.indexOf(parseInt(env.ADMIN_ROLE)) != -1) {
                    localStorage.setItem('role', env.ADMIN_ROLE);
                }else if (roles.indexOf(parseInt(env.GHR)) != -1) {
                    localStorage.setItem('role', env.GHR);
                }
                localStorage.setItem('login_success', 'true');
                localStorage.setItem('user_name', response.data.user_name);
                localStorage.setItem('token', response.token);
                localStorage.setItem('duplicate', response.token);
                localStorage.setItem('user_id', response.data.user_id);
                localStorage.setItem('active_status', response.data.active_status);
                if(response.data.loginvalidity<= this.today() || response.data.loginvalidity=="0000-00-00" ){
                    localStorage.setItem('login_expiry_status', "1");
                }else{
                localStorage.setItem('login_expiry_status', "0");
                }
                this.loadBasedOnRole();
            } else {
                this.toastr.error(response.message, 'Error');
            }

        });
    }

    loadBasedOnRole() {
        localStorage.setItem('token',localStorage.getItem('duplicate'));
        const role = localStorage.getItem('role');

        if (localStorage.getItem('login_success')) {
            if (role == env.CANDIDATE_ROLE) {
                this.router.navigate(['/dashboard/candidate/info']);
            } else if (role == env.HR_ROLE) {
                this.router.navigate(['/dashboard/hr/employeelist']);
            } else if (role == env.RECRUITMENT_ROLE) {
                this.router.navigate(['/dashboard/employee/employeeoverview']);
            }
            else if (role == env.EMPLOYEE_ROLE) {
                this.router.navigate(['/dashboard/employee/info']);
            } else if (role == env.ADMIN_ROLE) {
                this.router.navigate(['/dashboard/admin/hrlist']);
            } else if (role == env.GHR) {
                this.router.navigate(['/dashboard/hr/employeelist']);
            } else if (role == env.MANAGER_ROLE) {
                this.router.navigate(['/dashboard/employee/manageroverview']);
            } else if (role == env.FINANCE) {
                this.router.navigate(['/dashboard/employee/info']);
            }
        }
    }

}
