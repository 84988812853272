import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app.routing.module';
import {LoginComponent} from './login/login.component';
import {DashboardModule} from './layout/layout.module';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule,HTTP_INTERCEPTORS} from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import {ProgressBarModule} from "angular-progress-bar"
import { NgProgressModule  } from '@ngx-progressbar/core';
import { NgProgressHttpClientModule } from '@ngx-progressbar/http-client';
import { FullCalendarModule } from 'ng-fullcalendar';
import { GuideComponent } from './guide/guide.component';
import {FooterComponent} from "./layout/footer/footer.component";
import {DataTablesModule} from "angular-datatables";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Interceptor } from './interceptor';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ForgetpasswordComponent,
        ResetpasswordComponent,
        GuideComponent,
        FooterComponent,

    ],

    imports: [
        BrowserModule,
        HttpClientModule,
        DashboardModule,
        AppRoutingModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        FileUploadModule,
        FullCalendarModule,
        NgxSmartModalModule.forRoot(),
        ToastrModule.forRoot(),
        ProgressBarModule,
        NgProgressModule.forRoot(),
        NgProgressHttpClientModule,
        DataTablesModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot()

    ],

    providers: [HttpClientModule,{ provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true }],
    bootstrap: [AppComponent]
})
export class AppModule {

}
