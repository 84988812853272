import {Component, OnInit} from '@angular/core';
import {environment as env} from '../environments/environment';
import {Router,NavigationEnd,ActivatedRoute} from "@angular/router";
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
  title = 'Brt HRMS';

  constructor(private titleService: Title, private router: Router,
              private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {

    const appTitle = this.titleService.getTitle();
    this.router
        .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
    ).subscribe((ttl: string) => {
      this.titleService.setTitle(ttl);
    });

    //this.loadBasedOnRole();
    /*window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        let token = localStorage.getItem('token');
        if(token == undefined) { // you can update this as per your key
          // DO LOGOUT FROM THIS TAB AS WELL
          this.router.navigate(['']); // If you are using router
          // OR

        }
      }
    }, false);*/

    window.addEventListener('storage', (event) => {
      // there is a problem with IE (in all other browsers event not emitted in current window)
      // so need to check that it is the other tab that emitted event
      // and avoid infinite loop
      let token = localStorage.getItem('token');
      if (event.key == 'token') {
        if(!event.newValue){
          this.router.navigate(['']);
        }
        else {
          window.location.reload();
        }
      }
    }, false);

  }

  loadBasedOnRole() {

    const role = localStorage.getItem('role');

    if (localStorage.getItem('login_success') && localStorage.getItem('path') =='') {
      if (role == env.CANDIDATE_ROLE) {
        this.router.navigate(['/dashboard/candidate/info']);
      } else if (role == env.HR_ROLE) {
        this.router.navigate(['/dashboard/hr/employeelist']);
      } else if (role == env.EMPLOYEE_ROLE) {
        this.router.navigate(['/dashboard/employee/info']);
      }
      else if (role == env.MANAGER_ROLE) {
        this.router.navigate(['/dashboard/employee/manageroverview']);
      }else if (role == env.RECRUITMENT_ROLE) {
        this.router.navigate(['/dashboard/employee/employeeoverview']);
      }
    }
  }
}
