import {Injectable} from '@angular/core';
import {environment as env} from '../../../environments/environment';
import {HttpClient,HttpHeaders} from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    httpOptions:any;

    constructor(private _http: HttpClient) {

     /*   this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('token')
            })
        };*/
    }

    public loginSubmit(userData): any {
        const apiURL = env.BASE_URL + 'auth/login';
        return this._http.post(apiURL, userData);
    }

    public callLogoutApi() : any {
        const apiURL = env.BASE_URL + 'auth/logout';
        return this._http.get(apiURL);
    }
    /**
     * This function is to send reset password mail link
     * @param data
     * @returns {any}
     */
    public passwordResetEmail(data) : any {
        const apiURL = env.BASE_URL + 'password/email';
        return this._http.post(apiURL, data);
    }

    /**
     * This function is to reset password
     * @param data
     * @returns {any}
     */
    public resetPassword(data) : any {
        const apiURL = env.BASE_URL + 'password/reset';
        return this._http.post(apiURL, data);
    }

    /**
     * This function is to get contries
     * @param data
     * @returns {any}
     */
    public getCountry() : any {
        const apiURL = env.BASE_URL + 'get_countries';
        return this._http.get(apiURL);
    }

    /**
     * This function is to get states by country
     * @param data
     * @returns {any}
     */
    public getStateByCountry(data) : any {
        const apiURL = env.BASE_URL + 'get_states_by_country ';
        return this._http.post(apiURL, data);
    }
    /**
     * This function is to get states by country
     * @param data
     * @returns {any}
     */
    public getCityByState(data) : any {
        const apiURL = env.BASE_URL + 'get_cities_by_state ';
        return this._http.post(apiURL, data);
    }

    public getPolicyLists() : any {
        const apiURL = env.BASE_URL + 'get-policy ';
        return this._http.get(apiURL);
    }

    public deletePolicy(data) : any {
        const apiURL = env.BASE_URL + 'delete-policy ';
        return this._http.post(apiURL, data);
    }

    public getRecruiterCountry() : any {
        const apiURL = env.BASE_URL + 'list-recruiter-module-countries';
        return this._http.get(apiURL);
    }

    public getRecruiterCity(data) : any {
        const apiURL = env.BASE_URL + 'list-recruiter-module-cities';
        return this._http.post(apiURL, data);
    }

    public savePolicy(data) : any {
        const apiURL = env.BASE_URL + 'create-policy';
        return this._http.post(apiURL, data);
    }

    public

    contactSupport(data) : any {
        const apiURL = env.BASE_URL + 'contact-support';
        return this._http.post(apiURL, data);
    }




}
