import { Component, OnInit } from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from "@angular/router";
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.component.css']
})
export class LayoutComponent implements OnInit {

  constructor(private router: Router,private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {

  }

}
